@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .background {
    background: radial-gradient(44.27% 67.09% at 15.92% 50%, rgba(255, 0, 110, 0.12) 0%, rgba(58, 134, 255, 0.12) 100%), #262626;
  }

  .btn {
    @apply border-2 rounded-full w-full p-1 text-lg font-bold;
  }

  .btn-sm {
    @apply border-2 rounded-full py-0 px-3 font-bold;
  }

  .input {
    @apply rounded-full bg-zinc-800 px-3 py-2 w-full outline-none
  }

  .resize-none {
    resize: none;
  }
}


@layer base {
  ::-webkit-scrollbar {
    @apply w-1.5;
  }
  
  ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    @apply bg-zinc-950 rounded-full;
  }
}
